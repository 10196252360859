//css
require('../../css/bootstrap.min.css')
require('../../css/main.css')
require('../../css/style.css')
require('../../css/responsive.css')
require('../../rs-plugin/css/settings.css')
import('../../rs-plugin/font/revicons.woff')
import('../../rs-plugin/font/revicons.ttf')
import('../../rs-plugin/font/revicons.eot')
import('../../css/font-awesome.min.css')

//js
import('../../js/own-menu.js')
import('../../js/smooth-scroll.js')
import('../../js/jquery.prettyPhoto.js')
import('../../js/main.js')
